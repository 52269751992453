// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"

import "admin-lte/plugins/jquery/jquery.min"
import "admin-lte/plugins/bootstrap/js/bootstrap.bundle.min"
import "admin-lte/dist/js/adminlte.min"

import "@rails/request.js"
