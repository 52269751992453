import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["agent", "row", "price", "total"]

  connect() {
    this.calculate()
  }

  calculate() {
    let total = 0
    const formatter = new Intl.NumberFormat('ja-JP'),
      rate = this.agentTarget.selectedOptions[0].dataset.rate || 1

    this.rowTargets.forEach((element) => {
      const price = element.getElementsByClassName('js-item')[0].dataset.price,
        tax = element.getElementsByClassName('js-item')[0].dataset.tax,
        quantity = element.getElementsByClassName('js-quantity')[0].value,
        subTotal = price * rate * tax * quantity

      element.getElementsByClassName('js-purchase-price')[0].textContent = `¥${formatter.format(price * rate * tax)}`
      element.getElementsByClassName('js-sub-total')[0].textContent = `¥${formatter.format(subTotal)}`
      total += subTotal
    })

    this.totalTarget.textContent = `¥${formatter.format(total)}`
  }
}
