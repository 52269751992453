import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['target', 'template', 'acceptDate']
  static values = {
    wrapperSelector: {
      type: String,
      default: '.order-detail-wrapper'
    }
  }

  connect() {
    let rows = document.getElementsByClassName('order-detail-wrapper')
    rows = Array.prototype.slice.call(rows);
//    console.log(rows)
    rows.forEach((element) => {
      let obj = element.getElementsByTagName("select")[0]
      let idx = obj.selectedIndex
      if (!idx) return

      let selected = obj.options[idx]
    
      let tr = obj.closest("tr")
      const jpyen = new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' })
      // console.log(tr)
      tr.getElementsByClassName('list-price')[0].textContent = jpyen.format(selected.getAttribute("list-price"))
      tr.getElementsByClassName('price')[0].textContent = jpyen.format(selected.getAttribute("price"))
      tr.getElementsByClassName('price-input')[0].setAttribute('value', selected.getAttribute("price"))
      tr.getElementsByClassName('point')[0].textContent = jpyen.format(selected.getAttribute("point"))
      tr.getElementsByClassName('point-input')[0].setAttribute('value', selected.getAttribute("point"))
      tr.getElementsByClassName('tax')[0].textContent = `${selected.getAttribute("tax")} %`
      tr.getElementsByClassName('tax-input')[0].setAttribute('value', selected.getAttribute("tax"))
    })
  }

  fill(e) {
    e.preventDefault()

    let obj = e.target
    let idx = obj.selectedIndex
    let selected = obj.options[idx]
    
    let tr = obj.closest("tr")
    const jpyen = new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' })
    // console.log(tr)
    tr.getElementsByClassName('list-price')[0].textContent = jpyen.format(selected.getAttribute("list-price"))
    tr.getElementsByClassName('price')[0].textContent = jpyen.format(selected.getAttribute("price"))
    tr.getElementsByClassName('price-input')[0].setAttribute('value', selected.getAttribute("price"))
    tr.getElementsByClassName('point')[0].textContent = jpyen.format(selected.getAttribute("point"))
    tr.getElementsByClassName('point-input')[0].setAttribute('value', selected.getAttribute("point"))
    tr.getElementsByClassName('tax')[0].textContent = `${selected.getAttribute("tax")} %`
    tr.getElementsByClassName('tax-input')[0].setAttribute('value', selected.getAttribute("tax"))
  }

  add (e) {
    e.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    //this.targetTarget.insertAdjacentHTML('beforebegin', content)
    this.targetTarget.insertAdjacentHTML('beforeEnd', content)
  }

  remove (e) {
    e.preventDefault()

    // @ts-ignore
    const wrapper = e.target.closest(this.wrapperSelectorValue)
    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      if (confirm("本当に削除しますか。削除後、DBに削除を反映するには、「保存」ボタンをクリックしてください。")) {
        wrapper.style.display = 'none'
        const input = wrapper.querySelector("input[name*='_destroy']")
        input.value = '1'
      }
    }
  }

  putAcceptDate(e) {
    e.preventDefault()

    const orderDate = e.target.value
    let res = orderDate.replaceAll('-','').substring(0,6)
    this.acceptDateTarget.setAttribute('value', res)
  }
}

