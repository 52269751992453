import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import TomSelect  from "tom-select"

export default class extends Controller {
  static targets = ["bankFlgSelect", "bankSelect", "bankOfficeSelect", "bankFieldSet",
                    "introCustField", "upperCustField", "introCustSelect", "upperCustSelect"]
  static values = {
    url: String,
    param: String
  }

  connect() {
    this.createTS()
    this.enable({target:this.bankFlgSelectTarget})
    new TomSelect(this.introCustSelectTarget)
    new TomSelect(this.upperCustSelectTarget)
  }

  enable({ target }) {
    const elements = Array.from(this.element.elements)
    const selectedElements = "selectedOptions" in target ?
      target.selectedOptions :
      [ target ]

    for (const element of elements.filter(element => element.name == target.name)) {
      if (element instanceof HTMLFieldSetElement) {
        element.disabled = true
      }
    }
    
    this.bankSelectTarget.disabled = true
    this.disableTS()
    for (const element of controlledElements(...selectedElements)) {
      if (element instanceof HTMLFieldSetElement) {
        element.disabled = false
        if (element.getAttribute('id') == this.bankFieldSetTarget.id) {
          this.bankSelectTarget.disabled = false
          this.enableTS()
        } 
      }
    }
  }

  createTS() {
    this.tomSelectBankTarget = new TomSelect(this.bankSelectTarget)
    this.tomSelectBankOfficeTarget = new TomSelect(this.bankOfficeSelectTarget)
  }


  enableTS() {
    this.tomSelectBankTarget.enable()
    this.tomSelectBankOfficeTarget.enable()
  }

  disableTS() {
    this.tomSelectBankTarget.disable()
    this.tomSelectBankOfficeTarget.disable()
  }

  change(event) {
    let params = new URLSearchParams()
    params.append(this.paramValue, event.target.selectedOptions[0].value)
    params.append("target", this.bankOfficeSelectTarget.id)

    fetch(`${this.urlValue}?${params}`)
    .then(response => response.text())
    .then(html => {
      this.tomSelectBankOfficeTarget.clear()
      this.tomSelectBankOfficeTarget.clearOptions()
      this.bankOfficeSelectTarget.innerHTML = html
      this.tomSelectBankOfficeTarget.sync()
    })
  }

  fill_intro_customer(event) {
    let params = new URLSearchParams()
    params.append("customer_id", event.target.value)
    params.append("target", this.introCustFieldTarget.id)

    get(`/admin/customers/find_by_id.turbo_stream?${params}`, {
      responseKind: "turbo-stream"
    })
  }

  fill_upper_customer(event) {
    let params = new URLSearchParams()
    params.append("customer_id", event.target.value)
    params.append("target", this.upperCustFieldTarget.id)

    get(`/admin/customers/find_by_id.turbo_stream?${params}`, {
      responseKind: "turbo-stream"
    })
  }

}

function controlledElements(...selectedElements) {
  return selectedElements.flatMap(selectedElement =>
    getElementsByTokens(selectedElement.getAttribute("aria-controls"))
  )
}

function getElementsByTokens(tokens) {
  const ids = (tokens ?? "").split(/\s+/)

  return ids.map(id => document.getElementById(id))
}
