import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["trigger", "fieldset"]

  connect() {
    this.triggerTargets.forEach((element) => {
      if (element.type === "radio") {
        this.radioCollapse()
      } else if (element.type === "checkbox") {
        this.checkBoxCollapse()
      }
    })
  }

  radioCollapse() {
    this.triggerTargets.forEach((element) => {
      const value = element.value

      if (element.checked) {
        this.fieldsetTargets.forEach((fieldsetElement) => {
          if (value === fieldsetElement.name) {
            fieldsetElement.disabled = false
            fieldsetElement.classList.remove("d-none")
          }
        })
      } else {
        this.fieldsetTargets.forEach((fieldsetElement) => {
          if (value === fieldsetElement.name) {
            fieldsetElement.disabled = true
            fieldsetElement.classList.add("d-none")
          }
        })
      }
    })
  }

  checkBoxCollapse() {
    this.triggerTargets.forEach((element) => {
      if (element.checked) {
        this.fieldsetTargets.forEach((fieldsetElement) => {
          fieldsetElement.disabled = false
          fieldsetElement.classList.remove('d-none')
        })
      } else {
        this.fieldsetTargets.forEach((fieldsetElement) => {
          fieldsetElement.disabled = true
          fieldsetElement.classList.add('d-none')
        })
      }
    })
  }
}
